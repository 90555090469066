import React from 'react'

import styled from 'styled-components'

import { getFooterData, getHeaderData } from '../api'

import { default as normalizeHeaderData } from '@models/Header'
import { default as normalizeFooterData } from '@models/Footer'
import { default as normalizeBreadcrumb } from '@models/Breadcrumb'

import { Icon } from '@/atoms'
import Breadcrumb from '@/molecules/Breadcrumb'
import { SectionBuilder } from '@/organisms'
import Layout from '@/organisms/Layout'

export const getStaticProps = async () => {
  const [header, footer] = await Promise.all([getHeaderData(), getFooterData()])

  return {
    props: {
      metadata: {
        metaTitle: `Pagina non trovata`,
      },
      breadcrumb: normalizeBreadcrumb([{ label: '404' }]),
      footer: normalizeFooterData(footer),
      header: normalizeHeaderData(header),
      content: [
        {
          id: 'text404',
          title: {
            as: 'h1',
            typo: 'displayMD',
            content: 'Non trovi quello che stavi cercando?',
          },
          paragraph: {
            as: 'p',
            typo: 'paragraph1',
            content:
              'Sembra che questo contenuto non esista.<br>Prova a navigare il menù oppure riparti dalla nostra Homepage.',
          },
          ctaPrimary: {
            href: '/',
            label: 'Torna alla home',
          },
          align: 'center',
          space: 'sm',
          theme: 'white',
          type: 'RichText',
        },
      ],
    },
  }
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default function NotFound({
  metadata,
  breadcrumb,
  header,
  footer,
  content,
}) {
  return (
    <Layout
      metadata={metadata}
      headerData={header}
      footerData={footer}
    >
      {breadcrumb ? <Breadcrumb items={breadcrumb} /> : null}
      <StyledWrapper>
        <Icon
          name='page-error'
          ariaLabel='Icona pagina manutenzione'
          unlockedProportions
        />
        <SectionBuilder data={content} />
      </StyledWrapper>
    </Layout>
  )
}
